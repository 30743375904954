import React, { useState, useEffect } from 'react';
import Bgimg from '../../assets/svgs/pricing-page-bg.svg';
import { showSignup } from '../../scripts/createSignup';


type SessionValues = {
  [key: string]: {
    price: string;
  };
};

type PlanType = "FREE" | "TEAM" | "PROFESSIONAL" | "ENTERPRISE";
type Plan = {
  heading: PlanType;
  chargeWeb: string;
  info: string;
  services: string[];
  buttonText: string;
  buttonClass: string;
  className: string;
  to: string;
  external: boolean;
  sessionValuesTeam?: SessionValues;
  sessionValuesTeamMobile?: SessionValues;
  sessionValuesTeamMonthly?: SessionValues;
  sessionValuesTeamMobileMonthly?: SessionValues;
  sessionValuesPro?: SessionValues;
  sessionValuesProMobile?: SessionValues;
  sessionValuesProMonthly?: SessionValues;
  sessionValuesProMobileMonthly?: SessionValues;
};


const sessionValuesPro = {
  "10k sessions/mo": { "price": "$295" },
  "25k sessions/mo": { "price": "$460" },
  "50k sessions/mo": { "price": "$600" },
  "100k sessions/mo": { "price": "$720" },
  "175k sessions/mo": { "price": "$885" },
  "250k sessions/mo": { "price": "$995" },
  "300k sessions/mo": { "price": "$1,100" },
  "400K sessions/mo": { "price": "$1,270" },
  "500k sessions/mo": { "price": "$1,440" },
  "600k sessions/mo": { "price": "$1,595" },
  "700k sessions/mo": { "price": "$1,760" },
  "800k sessions/mo": { "price": "$1,925" },
  "900k sessions/mo": { "price": "$2,095" },
  "1m sessions/mo": { "price": "$2,200" }
};

const sessionValuesProMonthly = {
  "10k sessions/mo": { "price": "$350" },
  "25k sessions/mo": { "price": "$550" },
  "50k sessions/mo": { "price": "$720" },
  "100k sessions/mo": { "price": "$860" },
  "175k sessions/mo": { "price": "$1,060" },
  "250k sessions/mo": { "price": "$1,190" },
  "300k sessions/mo": { "price": "$1,320" },
  "400K sessions/mo": { "price": "$1,520" },
  "500k sessions/mo": { "price": "$1,720" },
  "600k sessions/mo": { "price": "$1,910" },
  "700k sessions/mo": { "price": "$2,110" },
  "800k sessions/mo": { "price": "$2,310" },
  "900k sessions/mo": { "price": "$2,510" },
  "1m sessions/mo": { "price": "$2,640" }
};

const sessionValuesProMobile = {
  "10k sessions/mo": { "price": "$350" },
  "25k sessions/mo": { "price": "$550" },
  "50k sessions/mo": { "price": "$720" },
  "100k sessions/mo": { "price": "$860" },
  "175k sessions/mo": { "price": "$1,060" },
  "250k sessions/mo": { "price": "$1,195" },
  "300k sessions/mo": { "price": "$1,320" },
  "400K sessions/mo": { "price": "$1,520" },
  "500k sessions/mo": { "price": "$1,720" },
  "600k sessions/mo": { "price": "$1,920" },
  "700k sessions/mo": { "price": "$2,110" },
  "800k sessions/mo": { "price": "$2,310" },
  "900k sessions/mo": { "price": "$2,510" },
  "1m sessions/mo": { "price": "$2,645" }
};

const sessionValuesProMobileMonthly = {
  "10k sessions/mo": { "price": "$420" },
  "25k sessions/mo": { "price": "$660" },
  "50k sessions/mo": { "price": "$860" },
  "100k sessions/mo": { "price": "$1,030" },
  "175k sessions/mo": { "price": "$1,270" },
  "250k sessions/mo": { "price": "$1,430" },
  "300k sessions/mo": { "price": "$1,580" },
  "400K sessions/mo": { "price": "$1,820" },
  "500k sessions/mo": { "price": "$2,060" },
  "600k sessions/mo": { "price": "$2,300" },
  "700k sessions/mo": { "price": "$2,530" },
  "800k sessions/mo": { "price": "$2,770" },
  "900k sessions/mo": { "price": "$3,010" },
  "1m sessions/mo": { "price": "$3,170" },
};

const sessionValuesTeam = {
  "10k sessions/mo": { "price": "$69" },
  "25k sessions/mo": { "price": "$139" },
  "50k sessions/mo": { "price": "$280" },
};

const sessionValuesTeamMonthly = {
  "10k sessions/mo": { "price": "$99" },
  "25k sessions/mo": { "price": "$199" },
  "50k sessions/mo": { "price": "$399" },
};

const sessionValuesTeamMobile = {
  "10k sessions/mo": { "price": "$139" },
  "25k sessions/mo": { "price": "$239" },
};

const sessionValuesTeamMobileMonthly = {
  "10k sessions/mo": { "price": "$199" },
  "25k sessions/mo": { "price": "$399" },
};

const data: Plan[] = [
  {
    heading: 'FREE',
    chargeWeb: '$0 / month',
    info: 'FREE FOREVER',
    services: ['Includes 1,000 Sessions / Month', '1 Month Data Retention'],
    buttonText: ' Create Account',
    buttonClass: 'btn btn-primary',
    className: 'plan developer',
    to: '/',
    external: false,
  },
  {
    heading: 'TEAM',
    chargeWeb: '',
    sessionValuesTeam,
    sessionValuesTeamMobile,
    sessionValuesTeamMonthly,
    sessionValuesTeamMobileMonthly,
    info: 'STARTING AT',
    services: [
      'Pixel-Perfect Session Replay',
      'JavaScript Error Reporting',
      'Monthly Commitment, 14 Day Trial',
    ],
    buttonText: 'Try Free for 14 Days',
    buttonClass: 'btn btn-primary',
    className: 'plan team',
    to: '/',
    external: false,
  },
  {
    heading: 'PROFESSIONAL',
    chargeWeb: '',
    sessionValuesPro,
    sessionValuesProMobile,
    sessionValuesProMonthly,
    sessionValuesProMobileMonthly,
    info: 'STARTING AT',
    services: [
      'AI-powered Struggle Detection',
      'Detailed Product Analytics',
      'Annual Commitment, 21 Day Trial',
    ],
    buttonText: 'Get a Demo',
    buttonClass: 'btn btn-primary',
    className: 'plan professional',
    to: '/request-demo',
    external: true,
  },
  {
    heading: 'ENTERPRISE',
    chargeWeb: 'Custom Pricing',
    info: 'CONTACT US',
    services: [
      'Custom Volume of Sessions',
      'Self Hosted Available',
      'Annual Commitment, 21 Day Pilot',
    ],
    buttonText: 'Get a Demo',
    buttonClass: 'btn btn-primary',
    className: 'plan enterprise',
    to: '/request-demo',
    external: true,
  },
];

type DetermineSessionValuesProps = {
  item: Plan,
  isMobile: boolean,
  isMonthly: boolean,
};

const determineSessionValues = ({ item, isMobile, isMonthly }: DetermineSessionValuesProps) => {
  if (item.heading === 'TEAM') {
    if (isMobile) {
      return isMonthly ? item.sessionValuesTeamMobileMonthly : item.sessionValuesTeamMobile;
    }
    return isMonthly ? item.sessionValuesTeamMonthly : item.sessionValuesTeam;
  }
  if (item.heading === 'PROFESSIONAL') {
    if (isMobile) {
      return isMonthly ? item.sessionValuesProMobileMonthly : item.sessionValuesProMobile;
    }
    return isMonthly ? item.sessionValuesProMonthly : item.sessionValuesPro;
  }
  return {};
};

const PricingPlans = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [isMonthly, setIsMonthly] = React.useState(false);

  const setMobile = (val: boolean) => {
    setIsMobile(val);
  };

  const setMonthly = (val: boolean) => {
    setIsMonthly(val);
  };

  const [selectedSessionValues, setSelectedSessionValues] = useState({
    TEAM: Object.keys(sessionValuesTeam)[0],
    PROFESSIONAL: Object.keys(sessionValuesPro)[0],
  });

  // handle potential mismatches between plans
  const handleSessionChange = (planType: PlanType, value: string) => {
    setSelectedSessionValues(prevState => {
      const sessionValues = determineSessionValues({ item: data.find(item => item.heading === planType), isMobile, isMonthly });
      // Check if value exists in sessionValues
      if (!sessionValues[value]) {
        // If not, select the first session from sessionValues
        const firstSession = Object.keys(sessionValues)[0];
        return { ...prevState, [planType]: firstSession };
      }
      // If it exists, just set the state as usual
      return { ...prevState, [planType]: value };
    });
  };

  // handle potential mismatches between isMobile and isMonthly state changes
  useEffect(() => {
    data.forEach((plan) => {
      if (plan.heading === 'TEAM' || plan.heading === 'PROFESSIONAL') {
        const sessionValues = determineSessionValues({ item: plan, isMobile, isMonthly });
        const keys = Object.keys(sessionValues);
        setSelectedSessionValues(prevValues => ({
          ...prevValues,
          [plan.heading]: keys.includes(prevValues[plan.heading]) ? prevValues[plan.heading] : keys[0]
        }));
      }
    });
  }, [isMobile, isMonthly]);

  // the next two determine which group of potential session values based on the plan
  const chargeAmount = (item: Plan) => {
    if (item.heading === 'TEAM' || item.heading === 'PROFESSIONAL') {
      const sessionValues = determineSessionValues({ item, isMobile, isMonthly });
      return sessionValues[selectedSessionValues[item.heading]]?.price || '';
    }
    return item.chargeWeb;
  };

  function openIntercomWithMessage(message, subject) {
    if (window.Intercom) {
      window.Intercom('showNewMessage', message);
    } else {
      window.open(`mailto:support@logrocket?body=${message}&subject=${subject}`, '_blank');
    }
  }

  return (
    <div className='pricing_plan'>
      <div className='pricing_plan-container' style={{ backgroundImage: `url(${Bgimg})` }}>
        <div className='row' >
          <div className='container'>
            <div className='col-md-12  d-flex justify-content-start justify-content-md-start align-items-center text-content '>
              <div >
                <h1>LogRocket Pricing </h1>
                <p>Plans that scale with your growth</p>
              </div>
            </div>
          </div>

          <div className='top_pricing_toggle d-flex justify-content-center col-md-12'>
            <div className='corner_box' role="button" tabIndex={0} onClick={() => { setMobile(!isMobile); window.lr_analytics.track('pricing-mobile-toggle', { event: 'event' }); }} onKeyDown={() => setMobile(!isMobile)}>
              <button type='button'
                className={
                  isMobile === true ? 'button_first active' : 'button_first'
                }>MOBILE</button>
              <button type='button'
                className={
                  isMobile === false ? 'button_second active' : 'button_second'
                }>WEB</button>
            </div>
            <div className='corner_box' role="button" tabIndex={0} onClick={() => { setMonthly(!isMonthly); window.lr_analytics.track('pricing-monthly-toggle', { event: 'event' }); }} onKeyDown={() => setMonthly(!isMonthly)}>
              <button type='button'
                className={
                  isMonthly === true ? 'button_first active' : 'button_first'
                }>MONTHLY</button>
              <button type='button'
                className={
                  isMonthly === false ? 'button_second active' : 'button_second'
                }>ANNUAL</button>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            {data.map((item) => (
              <div key={item.heading} className='col-md-3'>
                <div className={item.className}>
                  <div className='d-block mx-auto pricing_wrapper' />
                  <h3>{item.heading}</h3>
                  <h6>{item.info}</h6>
                  <div className="charge">
                    {item.sessionValuesTeam && (
                      <>
                        {determineSessionValues({ item, isMobile, isMonthly })[selectedSessionValues[item.heading]] && (
                          <span>
                            {
                              determineSessionValues({ item, isMobile, isMonthly })[selectedSessionValues[item.heading]].price
                            }{" "}
                            / month
                          </span>
                        )}
                        <select
                          value={selectedSessionValues[item.heading]}
                          onChange={(e) => handleSessionChange(item.heading, e.target.value)}
                        >
                            {Object.keys(determineSessionValues({ item, isMobile, isMonthly })).map((key) => (
                              <option value={key} key={key}>
                                {key}: {determineSessionValues({ item, isMobile, isMonthly })[key].price}/mo
                              </option>
                            ))}
                        </select>
                      </>
                    )}
                    {item.sessionValuesPro && (
                      <>
                        {determineSessionValues({ item, isMobile, isMonthly })[selectedSessionValues[item.heading]] && (
                            <span>
                              {
                                determineSessionValues({ item, isMobile, isMonthly })[selectedSessionValues[item.heading]].price
                              }{" "}
                              / month
                            </span>
                          )}
                          <div className='cond-rec-rectangle inner-plan-cta'>
                            <div>Less than 20 employess?<br />Ask about our
                              <button type='button' className='no-underline' onClick={() => openIntercomWithMessage('I would like more information about the startup plan', '')}>Startup Plan
                                <span className='cond-rec-link'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 20 20" fill="none"><path d="M18.7071 8.20711C19.0976 7.81658 19.0976 7.18342 18.7071 6.79289L12.3431 0.428932C11.9526 0.0384078 11.3195 0.0384078 10.9289 0.428932C10.5384 0.819457 10.5384 1.45262 10.9289 1.84315L16.5858 7.5L10.9289 13.1569C10.5384 13.5474 10.5384 14.1805 10.9289 14.5711C11.3195 14.9616 11.9526 14.9616 12.3431 14.5711L18.7071 8.20711ZM0 8.5H18V6.5H0V8.5Z" fill="white" /></svg>
                                </span>
                              </button>
                            </div>
                          </div>
                      </>
                    )}
                    {(item.heading === "FREE" || item.heading === "ENTERPRISE") && (
                      chargeAmount(item)
                    )}
                    {item.heading === "ENTERPRISE" ? (
                      <div className='cond-rec-rectangle inner-plan-cta'>
                        <div>&gt;1m sessions/mo? Capture only important sessions with
                          <a href="https://logrocket.com/features/conditional-recording" className='no-underline'>Conditional Recording
                            <span className='cond-rec-link'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 20 20" fill="none"><path d="M18.7071 8.20711C19.0976 7.81658 19.0976 7.18342 18.7071 6.79289L12.3431 0.428932C11.9526 0.0384078 11.3195 0.0384078 10.9289 0.428932C10.5384 0.819457 10.5384 1.45262 10.9289 1.84315L16.5858 7.5L10.9289 13.1569C10.5384 13.5474 10.5384 14.1805 10.9289 14.5711C11.3195 14.9616 11.9526 14.9616 12.3431 14.5711L18.7071 8.20711ZM0 8.5H18V6.5H0V8.5Z" fill="white" /></svg>
                            </span>
                          </a>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <ul className='service list-unstyled'>
                    {item.services.map((service) => (
                      <li key={service}>{service}</li>
                    ))}
                  </ul>
                  {item.external ? (
                    <a href={item.to} className={item.buttonClass}>
                      {item.buttonText}
                    </a>
                  ) : (
                    <button className={item.buttonClass} type='button' onClick={showSignup}>
                      {item.buttonText}
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlans;
